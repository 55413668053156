import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import React, { useEffect } from "react";
import Spinner from "./components/Spinner/spinner.component";
import {
  setCategories,
  setHomePageContent,
  setHomePageProducts,
} from "./redux/basic-api-data/basicApi.action";
import { useDispatch } from "react-redux";

const Home = React.lazy(() => import("./pages/home"));
const Products = React.lazy(() => import("./pages/products"));
const ProductsDetails = React.lazy(() => import("./pages/productsDetails"));
const Cart = React.lazy(() => import("./pages/cart"));
const AboutUs = React.lazy(() => import("./pages/aboutUs"));
const ContactUs = React.lazy(() => import("./pages/contactUs"));
const Gallery = React.lazy(() => import("./pages/gallery"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacyPolicy"));
const NotFound = React.lazy(() => import("./pages/404"));
const Blog = React.lazy(() => import("./pages/blog"));
const BlogDetails = React.lazy(() => import("./pages/blogDetails"));
const ProductsVideos = React.lazy(() =>
  import("./pages/Product Videos/videoGallery")
);
const Subscribe = React.lazy(() => import("./pages/subscribe"));

function App() {
  const dispatch = useDispatch();

  const getFooterContents = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/get-homepage-contents`
    );
    const result = await res.json();

    dispatch(setHomePageContent(result?.data));
  };

  const getCategories = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/get-categories`,
      {
        method: "POST",
      }
    );
    const result = await res.json();
    dispatch(setCategories(result.data));
  };

  useEffect(() => {
    getFooterContents();
    getCategories();
  }, []);
  return (
    <Router>
      <React.Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/products/:id" exact component={Products} />
          <Route path="/products/:id/:sub_cat" exact component={Products} />
          <Route path="/details/:id" exact component={ProductsDetails} />
          <Route path="/cart" exact component={Cart} />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route path="/gallery" exact component={Gallery} />
          <Route path="/products-videos" exact component={ProductsVideos} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/blog/:id" exact component={BlogDetails} />
          <Route path="/subscribe" exact component={Subscribe} />
          <Route component={NotFound} />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
