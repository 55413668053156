export const setHomePageContent = (data) => {
  return {
    type: "SET_HOME_PAGE_CONTENT",
    payload: data,
  };
};

export const setCategories = (data) => {
  return {
    type: "SET_CATEGORIES",
    payload: data,
  };
};

export const setHomePageProducts = (data) => {
  return {
    type: "SET_HOME_PAGE_PRODUCTS",
    payload: data,
  };
};
