import { ADD_TO_CART, REMOVE_FROM_CART, SYNC_LOCAL_TO_CART, EMPTY_CART } from "./cart.types";
/*
   cart should hava 
      * id,
      * image,
      * price,
      * quantity
*/

const initialState = {
  cart_items: [],
};

const cartReducer = (state = initialState, action) => {
   switch (action.type) {
     case ADD_TO_CART:
      let tmpItems = [...state.cart_items, action.payload]
      // console.log("tmp items : ", tmpItems)
      window.localStorage.setItem("spaCart", JSON.stringify(tmpItems))
      return {
         ...state,
         cart_items: [...state.cart_items, action.payload]
      };
    case REMOVE_FROM_CART:
      let filteredArray = state.cart_items.filter(item => {
        return item.id !== action.payload // payload contains the id
      })
      if(filteredArray.length <= 0) {
        window.localStorage.setItem("spaCart", null)
      } else {
        window.localStorage.setItem("spaCart", JSON.stringify(filteredArray))
      }
      return {
        ...state,
        cart_items: filteredArray
      }
    case SYNC_LOCAL_TO_CART:
      return {
        ...state,
        cart_items: action.payload
      }
    case EMPTY_CART:
      window.localStorage.setItem("spaCart", null)
      return {
        ...state,
        cart_items: []
      }
    default:
      return state;
  }
};

export default cartReducer;
