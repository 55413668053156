import { combineReducers } from "redux";
import cartReducer from "./cart/cart.reducer";
import BasicApiData from "./basic-api-data/basicApiReducer";

const rootReducer = combineReducers({
  cart: cartReducer,
  basicApiData: BasicApiData,
});

export default rootReducer;
