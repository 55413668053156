const initialState = {
  homePageContent: {},
  categories: [],
  homePageProducts: [],
};

const BasicApiData = (state = initialState, action) => {
  switch (action.type) {
    case "SET_HOME_PAGE_CONTENT":
      return {
        ...state,
        homePageContent: action?.payload,
      };
    case "SET_CATEGORIES":
      return {
        ...state,
        categories: action?.payload,
      };
    case "SET_HOME_PAGE_PRODUCTS":
      return {
        ...state,
        homePageProducts: action?.payload,
      };
    default:
      return state;
  }
};

export default BasicApiData;
